<template>
  <v-app>
    <v-container v-if="isVerified" class="bg-white h-100 py-0">
      <v-row>
        <v-col v-if="isRejected" md="12" class="px-10 py-0">
          <div class="agreement-approved agreement-rejected my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">&#x2715;</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your rejection against agreement #
                {{ agreement_detail.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else-if="isSubmitted" md="12" class="px-10 py-0">
          <div class="agreement-approved my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your approval against agreement #
                {{ agreement_detail.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else md="12" class="px-10 py-0">
          <v-layout class="mx-15 my-5">
            <v-flex md4 class="my-auto">
              <div class="brand-logo">
                <img :src="siteLogo()" alt="Logo" />
              </div>
            </v-flex>
            <v-flex
              md4
              class="my-auto text-center font-size-24 font-weight-700"
              >{{ agreement_detail.page_title }}</v-flex
            >
            <v-flex md4 class="my-auto text-right">
              <v-btn
                class="custom-bold-button white--text"
                color="cyan"
                v-on:click="consentDialog = true"
                >Accept</v-btn
              >
              <v-btn
                class="custom-bold-button white--text mx-4"
                color="red lighten-1"
                v-on:click="rejectDialog = true"
                >Reject</v-btn
              >
              <v-btn class="custom-bold-button" v-on:click="closeWindow()"
                >Close</v-btn
              >
            </v-flex>
          </v-layout>
          <v-container fluid v-bar class="create-agreement-vbar agreement">
            <v-form
              ref="agreementForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="consentAgreement()"
            >
              <v-sheet class="mx-20 mb-20">
                <p class="t-bold text-center agr-border-bottom mb-8">
                  GENIC SOLUTIONS PTE LTD<br />STANDARD RESIDENTIAL RENOVATION
                  CONTRACT
                </p>
                <p class="d-flex">
                  THIS AGREEMENT is made on the
                  {{ formatDate(agreement_detail.startDate) }}
                  day of
                  {{ formatDate(agreement_detail.endDate) }}
                  between
                </p>
                <p class="my-4 d-flex">
                  (1)
                  {{ agreement_detail.ownerName }}
                  (NRIC No:
                  {{ agreement_detail.ownerNRIC }}) (hereinafter referred to as
                  the "<span class="t-bold">Owner</span>") of the one party
                </p>
                <p class="my-4">and</p>
                <p class="my-4">
                  <span class="mr-2">(2)</span>{{ companyName }} (a company with
                  limited liability incorporated under the laws of Singapore),
                  of {{ companyAddress }} (hereinafter referred to as the "<span
                    class="t-bold"
                    >Agreementor</span
                  >") of the other party.
                </p>
                <p class="mt-8 mb-3">WHEREAS:</p>
                <p class="d-flex">
                  <span class="mr-2">(1)</span>The Owner requires renovating of
                  the premises located at
                  {{ agreement_detail.ownerAddress }}
                  (hereinafter called the "Premises") and is engaging the
                  services of the Agreementor for this purpose.
                </p>
                <p>
                  <span class="mr-2">(2)</span>The Agreementor accepts such
                  appointment and is willing and able to carry out the work
                  described in the Scope of Works attached and in accordance
                  with the plans, drawings and specifications specified in
                  Appendix A (hereafter called the "<span class="t-bold"
                    >Works</span
                  >").
                </p>
                <p class="mt-8 mb-3">NOW IT IS HEREBY AGREED as follows:</p>
                <p class="t-bold mb-3">SCOPE OF WORKS</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">1.</span>The Agreementor agrees
                    and undertakes to carry out and complete the Works to the
                    satisfaction of the Owner and in accordance with the terms
                    and conditions of this Agreement.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">2.</span>Subject to the Owner
                    obtaining the relevant authorities approval (if any), the
                    Agreementor shall carry out and complete the Works as
                    approved by the Owner diligently and in accordance with the
                    said plans, drawings, variation addendum and specifications
                    ("<span class="t-bold">Agreement Documents</span>") on or
                    before the Date for completion of the Works. To this end,
                    the Agreementor shall supervise the work of its employees
                    and agents accordingly and that all finishes, furnishing and
                    furniture are of specified or satisfactory quality and
                    finish.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">3.</span>This agreement
                    constitutes the entire agreement unless written in this
                    agreement the Agreementor shall not be deemed to have made
                    any representation and/ or give any warranties to the Owner.
                  </li>
                </ul>
                <p class="t-bold mb-3">PAYMENT AND PROCEDURE</p>
                <ul>
                  <li class="d-flex">
                    <span class="mr-2 li-cnt">4.</span>For the performance of
                    the Works, the Owner agrees to pay to the Agreementor the
                    sum of Singapore Dollars
                    {{ agreement_detail.amountInAlpha }}
                    (
                    {{ formatMoney(agreement_detail.amountInNumber) }}
                    ) (hereinafter referred to as the "<span class="t-bold"
                      >Agreement Sum</span
                    >") in the manner specified in Appendix B.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">5.</span>In the event of any delay
                    or default by the Owner in making payment the Agreementor
                    may cease all work forthwith.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">6.</span>All payment can be made
                    payable to {{ companyName }} by cheque or Internet banking.
                    The Agreementor does not accept payment by cash or cash
                    cheques. If the Owner makes payment to the Agreementor or
                    the agreementor’s representatives by cash or cheques, they
                    will be solely responsible for any loss incurred thereby.
                    Notwithstanding, the agreementor is still entitled to claim
                    against the Owner for the balance of the agreement sum due
                    and owed to the Agreementor and any loss or damage incurred
                    thereby.
                  </li>
                </ul>
                <p class="t-bold mb-3">REPRESENTATIONS AND WARRANTIES</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">7.</span>The Agreementor warrants
                    that any materials supplied by the Agreementor will be of
                    specified or satisfactory quality, suitable for their
                    intended use and shall correspond with their description and
                    sample (if any).
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">8.</span>Where laying of floor
                    finishes forms a part of the Works to be carried out by the
                    Agreementor under this Agreement, the Agreementor shall
                    ensure that such floor finishes are laid in accordance with
                    the instructions and advice of the suppliers.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">9.</span>Where the materials for
                    floor finishes are furnished by the Owner, the Agreementor
                    shall adhere to the instructions of the manufacturers of
                    such materials.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">10.</span>The Agreementor will
                    execute and complete in a good and workmanlike manner the
                    work shown in <span class="t-bold">Appendix A</span> upon
                    the said drawings and as described subject to such
                    variations. For avoidance of doubt, all drawings and as
                    described subject to such variations as may be differences
                    between the drawings and the installed or fabricated
                    product. These differences shall not be the subject of any
                    objections or dispute by the Owner.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">11.</span>All design drawing and
                    ideas are copyright of the Agreementor. The final design
                    might change subject to actual site measurement before
                    commencement of work.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">12.</span>All marble tiles being
                    natural products possess their own characteristics and veins
                    and every piece will not resemble each other in characters
                    and tonality. Therefore, The Agreementor will not be
                    responsible for any complaints pertaining to the natural
                    characteristics or marble tiles.
                  </li>
                </ul>
                <p class="t-bold mb-3">ACCESS TO OWNER’S PREMISES</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">13.</span>The Owner shall obtain
                    the necessary permission for the Agreementor, his employees
                    or agent to carry out the necessary work.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">14.</span>The Owner agrees to
                    allow the Agreementor, agents and representatives within 6
                    months from the date of the acceptance of this acceptance of
                    this agreement to enter upon and have free access to the
                    Owner’s property (at all reasonable hours) for the purposes
                    of work. Owners are advised to refrain from having any
                    valuable items in the house during the period of renovation.
                    The Agreementor will not be liable for any loss of items
                    during the renovation process.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">15.</span>The Owner will at
                    his/her sole costs and the expense apply for and obtain all
                    necessary approvals, licenses, permits and any submission
                    fee required by the relevant authorities(e.g. Professional
                    Engineer (P.E), Qualified Person (Q.P), Fire Safety Bureau
                    (FSB), Building and Construction Authority (BCA) and comply
                    with all relevant laws and provisions in respect of the work
                  </li>
                </ul>
                <p class="t-bold mb-3">STANDARD OF WORKS</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">16.</span>The Agreementor shall,
                    at his own expense, remove all tools and surplus materials
                    from the premises and leave it in a clean and tidy
                    condition, upon completion of the Works or the termination
                    of the Agreement whichever the earlier. Chemical washing or
                    acid cleaning is meant for general cleaning only. Owners are
                    advised to do their own cleaning before moving in.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">17.</span>(a) Any defects,
                    shrinkage or other faults arising from materials supplied by
                    the Agreementor or workmanship not in accordance with the
                    Agreement which may appear within the defects liability
                    period stated in <span class="t-bold">Appendix A</span> and
                    which are notified by the Owner in writing to the
                    Agreementor from time to time but no later than 7 days from
                    the expiration of the said defects liability period or such
                    time may be agreed by the parties, shall be made good by the
                    Agreementor at his own expense within reasonable time frame
                    according to the number of defects after receipt of such
                    notification.
                  </li>
                  <li>
                    (b) Should the Agreementor not perform the Rectification
                    Works, subject to sub-clause (c), the Agreementor agrees to
                    compensate the Owner for the cost of engaging a third party
                    to perform the Rectification Works.
                  </li>
                  <li>
                    (c) The Owner shall inform the Agreementor in writing of the
                    cost of engaging a third party to perform the Rectification
                    Works and allow the Agreementor, at his own expense to
                    perform the Rectification Works.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">18.</span>Unless otherwise stated
                    in this agreement, all furniture fittings and work do not
                    include electrical and lighting work. Owners are not obliged
                    to undertake agreementors (e.g. aircon, curtains, etc)
                    recommended by sales executive. The Agreementor shall not be
                    held responsible for any work done other than work stated in
                    agreement.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">19.</span>Any omission and
                    itemization from the signed agreement shall be subjected to
                    the Agreementor’s approval.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">20.</span>If authorized by the
                    Owner or his/her representative or agent, the Agreementor
                    will vary the work by the addition, omission or substitution
                    of any work and no such variation shall vitiate this
                    agreement.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">21.</span>Such variation in
                    default of the agreement as to value, which should wherever
                    practicable be made before the variation is carried out,
                    shall be fair amount having regard to the circumstances in
                    which the variation was authorized and carried out. The
                    value of all such variations shall be added to or deducted
                    from the agreement price as the case may be.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">22.</span>Will not responsible for
                    any damage, failure, misalignment etc. Good gesture work
                    that carry out which not included in the agreement or
                    complimentary item.
                  </li>
                </ul>
                <p class="t-bold mb-3">WARRANTY</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">23.</span>The Agreementor shall
                    provide to the client a workmanship warranty ("<span
                      class="t-bold"
                      >Warranty</span
                    >") for a period of 12 months ("<span class="t-bold"
                      >Warranty Period</span
                    >") from the completion date of the Works and has a
                    replacement time frame of 4-8 weeks. Any materials due to
                    wear and tear are not covered under warranty.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">24.</span>In the event of any
                    defects arising from the Works during the Warranty Period,
                    the Agreementor shall at its own cost, conduct the necessary
                    rectifications works.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">25.</span>The Warranty shall not
                    be valid where:
                  </li>
                  <li>
                    (i) The Works have been completed to the satisfaction of the
                    Owner but the Owner has not made full payment of the
                    Agreement Sum;
                  </li>
                  <li>
                    (ii) The Owner refuses for whatsoever reason to allow the
                    Agreementor to conduct any rectification works during
                    operating hours 9-6pm.
                  </li>
                  <li>
                    (iii) The Agreementor is able to show that the defects are
                    as a result of misuse or wilful act by the Owner, his
                    employees, agents or third party working for or under the
                    directions of the Owner.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">26.</span>Any exchanges and
                    transfers partially consumed are non-refundable. A signed
                    agreement/agreement has to commence within 3 months from the
                    agreement stated date otherwise the agreement will be
                    terminated and the deposit of the total agreement sum would
                    be forfeited.
                  </li>
                </ul>
                <p class="t-bold mb-3">PERMITS AND APPROVALS</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">27.</span>The Agreementor shall
                    assist the Owner to obtain the necessary renovations permits
                    as required by the relevant authorities. Subject to the
                    Agreementor providing the Owner an estimate of the
                    reasonable expenses incurred, all expenses related to the
                    application of the renovations permits shall be borne by the
                    Owner.
                  </li>
                </ul>
                <p class="t-bold mb-3">TERMINATION</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">28.</span>In the event that the
                    Works are not of an acceptable standard, or if the
                    Agreementor ceases work on the Premises without reasonable
                    explanation for more than 2 consecutive months, the Owner
                    may terminate the Agreement by paying the Agreementor only
                    the value of the Works already performed, less compensation
                    for inconvenience or additional expense caused as a result
                    thereof or the Owner may exercise and enforce their strict
                    legal rights for such stoppage.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">29.</span>In the event that this
                    Agreement is terminated by the Owner for whatever reason
                    through no fault or negligence on the part of the
                    Agreementor, the Agreementor shall be entitled to recover
                    from the Client to claim up to the value of the Works
                    already carried out, including such amounts in respect of
                    any materials supplied or purchased, work prepared
                    (partially or fully), in addition to 20% of the agreement
                    sum.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">30.</span>In the event that this
                    Agreement is terminated by the Agreementor for whatever
                    reason through no fault or negligence on the part of the
                    Agreementor, the Owner shall be entitled to recover from the
                    Agreementor the difference in the cost required to complete
                    the Works.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">31.</span>In the event that a
                    transaction was to be cancelled, any deposited amount is
                    non-refundable to the customer. Deposits for reservations
                    are non-refundable.
                  </li>
                </ul>
                <p class="t-bold mb-3">CONFIDENTIALITY</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">32.</span>We ensure the
                    confidentiality and security of customers’ data by storing
                    them in locked cabinets with the access from management and
                    authorized personnel only. Our official receipts also
                    indicate confidentiality with the purpose of use for such
                    information and particulars is solely for the related
                    services only and with the permission of customers for any
                    other usages.
                  </li>
                </ul>
                <p class="t-bold mb-3">NO VARIATION</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">33.</span>No variation of the
                    Works described shall invalidate the Agreement, but any such
                    variation, whether by addition, omission or substitution,
                    together with the cost and effect on the Date for
                    commencement and completion of the Works, shall be agreed in
                    writing between the Owner and the Agreementor before the
                    variation is carried out, and the agreements sum stated in
                    Clause (2) and the Date for commencement and / or completion
                    of the Works stated in
                    <span class="t-bold">Appendix A</span>
                    shall be altered accordingly.
                  </li>
                </ul>
                <p class="t-bold mb-3">NON-ASSIGNMENT</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">34.</span>The Agreementor shall
                    not assign, transfer or in any other manner make over to any
                    third party the benefit and/or burden of this Agreement
                    without prior written consent of the Owner.
                  </li>
                </ul>
                <p class="t-bold mb-3">NOTICE</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">35.</span>Any notice or demand
                    under this Agreement may be sent by certificate of posting
                    to the Owner or Agreementor (as the case may be) at his
                    address as stated herein or in any other modes as agreed by
                    the Parties.
                  </li>
                </ul>
                <p class="t-bold mb-3">NON-WAIVER</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">36.</span>No failure to exercise
                    and no delay in exercising on the part of the Owner or
                    Agreementor (as the case may be) shall operate as a waiver
                    thereof nor shall any single or partial exercise of any
                    right, power or privilege preclude any other or further
                    exercise thereof or any other right, power or privilege.
                  </li>
                </ul>
                <p class="t-bold mb-3">GOVERNING LAW AND JURISDICTION</p>
                <ul>
                  <li>
                    <span class="mr-2 li-cnt">37.</span>Without prejudice to any
                    rights of the parties, the Agreementor agrees that any
                    disputes arising out of or in connection with this Agreement
                    shall be first referred to the Consumers Association of
                    Singapore (CASE) Mediation Centre for resolution by
                    mediation.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">38.</span>This Agreement shall be
                    governed by the laws of the Republic of Singapore.
                  </li>
                  <li>
                    <span class="mr-2 li-cnt">39.</span>This Agreement shall be
                    applicable to residential renovation works only.
                  </li>
                </ul>
                <p class="mt-8 t-bold mb-3">
                  AS WITNESS the hands of the parties hereto the day and year
                  first above written.
                </p>
                <v-layout class="mb-8">
                  <v-flex md4 class="link" v-on:click="consentDialog = true">
                    <p>SIGNED by the abovementioned</p>
                    <p class="sign-top t-bold mb-4 margin-top-100px">OWNER</p>
                    <p class="my-2">Name: {{ agreement_detail.ownerName }}</p>
                    <p class="my-2">
                      NRIC No.: {{ agreement_detail.ownerNRIC }}
                    </p>
                  </v-flex>
                  <v-flex md4></v-flex>
                  <v-flex md4>
                    <p>SIGNED by the abovementioned</p>
                    <v-img
                      v-if="sales_signature"
                      contain
                      class="my-4"
                      max-height="100"
                      :src="sales_signature"
                    ></v-img>
                    <p
                      class="sign-top t-bold mb-4"
                      :class="{ 'margin-top-100px': !sales_signature }"
                    >
                      {{ companyName }}
                    </p>
                    <p class="my-2">Name: {{ systemName }}</p>
                    <p class="my-2">NRIC No.: {{ systemNRIC }}</p>
                  </v-flex>
                </v-layout>
                <div class="page-paper">
                  <p class="t-bold mt-8 mb-3 text-center">APPENDIX A</p>
                  <p class="t-bold mt-8 mb-3 text-center">WORKS</p>
                  <ul>
                    <li>
                      <span class="mr-2 li-cnt">1.</span>Drawings (attached)
                    </li>
                    <li>
                      <span class="mr-2 li-cnt">2.</span>Stages of Site (to
                      identify) including dates of completion
                    </li>
                    <li>
                      <span class="mr-2 li-cnt">3.</span>Defect / liability
                      period (to specify)
                    </li>
                  </ul>
                </div>
                <div class="page-paper">
                  <p class="t-bold mt-8 mb-3 text-center">APPENDIX B</p>
                  <p class="t-bold mt-8 mb-3 text-center">PAYMENT SCHEDULE</p>
                  <table width="100%" height="100%" cellspacing="2px;">
                    <tr>
                      <td colspan="3" class="t-bold">
                        GENERAL RENOVATION WORK (BUILDERS’WORK; MECHANICAL,
                        ELECTRICAL, PLUMBING, ETC)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        The Agreementor shall be entitled to payment by account
                        by the Owner from time to time of the percentage of the
                        agreement price adjusted or varied in accordance with
                        this agreement at the stage following that is to say:
                      </td>
                      <td>% payable at various stages</td>
                      <td>Amount to be paid at various stages ($)</td>
                    </tr>
                    <tr
                      v-for="(row, index) in agreement_detail.paymentSchedule"
                      :key="index"
                    >
                      <td>
                        <div class="d-flex">
                          <span class="mr-4">{{ index + 1 }}.</span>
                          {{ row.text }}
                        </div>
                      </td>
                      <td>{{ row.percent }}%</td>
                      <td>
                        {{ formatMoney(row.amount) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>{{ totalPercentage }}%</td>
                      <td>{{ formatMoney(totalAmount) }}</td>
                    </tr>
                  </table>

                  <p class="mt-4">
                    * For any variation order (if any), full payment shall be
                    made upon confirmation.
                  </p>
                </div>
              </v-sheet>
            </v-form>
          </v-container>
          <Consent
            :consent-dialog="consentDialog"
            :attributes="agreement_detail"
            :company-name="companyName"
            :company-address="companyAddress"
            v-on:submit="updateOrCreate"
            v-on:close="consentDialog = false"
          ></Consent>
        </v-col>
      </v-row>
    </v-container>
    <Dialog :commonDialog.sync="dialog">
      <template v-slot:title> Verification </template>
      <template v-slot:body>
        <v-form
          ref="verificationForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="verifyCustomer"
        >
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model.trim="verificationCode"
                dense
                filled
                label="Security Code"
                solo
                flat
                :disabled="formLoading || pageLoading"
                :loading="formLoading"
                color="cyan"
                :rules="[
                  validateRules.required(verificationCode, 'Security Code'),
                  validateRules.minLength(verificationCode, 'Security Code', 4),
                  validateRules.maxLength(
                    verificationCode,
                    'Security Code',
                    10
                  ),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="verifyCustomer"
        >
          Submit
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog.sync="rejectDialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <h4>Are you sure ? You want to reject this agreement ?</h4>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="red lighten-1"
          v-on:click="rejectAgreement()"
        >
          Yes ! Reject
        </v-btn>
        <v-btn
          :disabled="formLoading || pageLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="rejectDialog = false"
        >
          No ! Cancel
        </v-btn>
      </template>
    </Dialog>
    <template v-if="validationErrorBag.length">
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        color="error"
        right
        v-for="(error, index) in validationErrorBag"
        :key="index"
      >
        {{ error }}
      </v-snackbar>
    </template>
  </v-app>
</template>
<style type="text/css" scoped>
.page-paper {
  min-height: 842px;
}

.agreement-approved {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.agreement-approved h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.agreement-approved p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.agreement-approved i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.agreement-approved .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
.agreement-rejected p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.agreement-rejected i {
  color: #ed3e46;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.agreement-rejected {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.agreement-rejected h1 {
  color: #ed3e46;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
</style>
<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { VERIFY_AGREEMENT_CUSTOMER } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";
import Consent from "@/view/pages/agreement/Consent";

export default {
  name: "agreement-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      dialog: true,
      formValid: true,
      formLoading: false,
      isRejected: false,
      isVerified: false,
      rejectDialog: false,
      isSubmitted: false,
      pageLoading: false,
      consentDialog: false,
      verificationCode: null,
      securityToken: null,
      today_date: null,
      validationErrorBag: new Array(),
      agreement_detail: new Object(),
      totalPercentage: null,
      totalAmount: null,
      systemName: null,
      systemNRIC: null,
      agreementId: null,
      company_detail: null,
      sales_signature: null,
      agreementTitle: "RESIDENTIAL RENOVATION CONTRACT",
      companyName: "Genic Solution Pte Ltd.",
      companyAddress: "8 Burn Road, #07-16 Trivex, Singapore 369977",
    };
  },
  components: {
    Dialog,
    Consent,
  },
  methods: {
    rejectAgreement() {
      this.formLoading = true;
      this.$store
        .dispatch(VERIFY_AGREEMENT_CUSTOMER, {
          url: "agreements/customer/reject",
          data: { code: this.verificationCode },
        })
        .then(() => {
          this.isRejected = true;
        })
        .catch((data) => {
          if (data && data.data && data.data.message) {
            this.validationErrorBag = data.data.message.split("<br>");
          } else {
            this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    updateOrCreate(param) {
      this.formLoading = true;
      this.$store
        .dispatch(VERIFY_AGREEMENT_CUSTOMER, {
          url: "agreements/customer/submit",
          data: { code: this.verificationCode, signature: param },
        })
        .then(() => {
          this.isSubmitted = true;
        })
        .catch((data) => {
          if (data && data.data && data.data.message) {
            this.validationErrorBag = data.data.message.split("<br>");
          } else {
            this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    updateTotal() {
      let percent = [];
      let amount = [];
      for (
        let i = this.agreement_detail.paymentSchedule.length - 1;
        i >= 0;
        i--
      ) {
        const percentInt =
          this.lodash.toNumber(
            this.agreement_detail.paymentSchedule[i].percent
          ) || 0;
        const amountInt =
          this.lodash.toNumber(
            this.agreement_detail.paymentSchedule[i].amount
          ) || 0;
        amount.push(amountInt);
        percent.push(percentInt);
      }
      this.totalPercentage = this.lodash.sum(percent);
      this.totalAmount = this.lodash.sum(amount);
    },
    verifyCustomer() {
      const _this = this;

      if (!_this.$refs.verificationForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_AGREEMENT_CUSTOMER, {
          url: "agreements/verify",
          data: { code: _this.verificationCode },
          token: _this.securityToken,
        })
        .then(({ data }) => {
          _this.agreement_detail = data.agreement_detail;
          _this.company_detail = data.company_detail;
          _this.companyName = data.company_detail.company_name;
          _this.companyAddress = data.company_detail.address;
          _this.systemName = data.company_detail.company_name;
          _this.systemNRIC = data.company_detail.company_nric;
          _this.sales_signature = data.sales_signature;
          _this.today_date = data.today_date;
          _this.dialog = false;
          _this.isVerified = true;
          _this.updateTotal();
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.VUE_APP_BASE_URL + logo;
    },
    closeWindow() {
      window.close();
    },
  },
  created() {
    const _this = this;

    if (_this.$route.query && _this.$route.query.token) {
      _this.securityToken = _this.$route.query.token;
    } else {
      _this.dialog = false;
      _this.closeWindow();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
